module.exports = {
  siteTitle: 'Destiny Books and Gifts', // <title>
  manifestName: 'Destiny Books and Gifts',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#8e52a1',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Namaste',
  subHeading: 'Welcome to Destiny Books & Gifts ',

  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/anubhavsrivastava',
    },
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://linkedin.com/in/theanubhav/',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/onlyanubhav',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://facebook.com/theanubhav',
    },
  ],
  email: 'info@destinybooksandgifts.co.za',
  phone: '000-00000',
  address: 'Shop 20, Windermere Shopping Centre., 163 Lilian Ngoyi Rd, Morningside, Durban, 4001, South Africa',
};
